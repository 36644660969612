import { ChildDataProps, graphql } from '@apollo/client/react/hoc'
import { gql } from '@apollo/client'
import { compose } from '../lib'
import hasRuntimeQuery from './hocs/hasRuntimeQuery'
import hasLoading from './hocs/hasLoading'

export type InputProps = {
  id?: Number
  slugType?: string
  otherParams?: string
}

export type Response = {
  getDynamicUrl: {
    marketing: {
      permalink: string
    }
  }
}

type Variables = {
  id?: Number
  slugType?: string
  otherParams?: string
}

type ChildProps = ChildDataProps<InputProps, Response>

export const urlMappingQuery = graphql<
  InputProps,
  Response,
  Variables,
  ChildProps
>(
  gql`
    query MAPPEDURL($id: Int, $slugType: String) {
      getDynamicUrl(id: $id, slugType: $slugType) {
        marketing {
          permalink
        }
      }
    }
  `,
  {
    options: ({ id, slugType }) => ({
      variables: { id, slugType }
    })
  }
)

const wrapper = ({ data, otherParams }: ChildProps) => {
  const { getDynamicUrl } = data
  const { variables } = data
  const { slugType } = variables
  const { id } = variables
  if (
    getDynamicUrl != null &&
    getDynamicUrl.marketing != null &&
    getDynamicUrl.marketing.permalink != null
  ) {
    if (slugType === 'sf-re' || slugType === 'sf-pr') {
      window.location.replace(
        `/${getDynamicUrl.marketing.permalink}${otherParams}${window.location.hash}`
      )
    } else {
      window.location.replace(`/${getDynamicUrl.marketing.permalink}`)
    }
  } else {
    window.location.replace(`/404.html?t=${slugType}&id=${id}`)
  }
  window.history.pushState({}, window.location.href)
  // return <DynamicNotFoundPage default />
}
export default compose([hasRuntimeQuery, urlMappingQuery, hasLoading])(wrapper)
