import React from 'react'
import UrlMapping from './urlMapping'

let slugType: string
let id: number
let otherUrlParams: string

type Props = {
  url: string
}

/**
 * Extract the report ID and any URL parameters from a report URL
 * @param qURL the URL to search, usually of the form /site/pr/<ID> or /site/re/<ID>
 * @returns [slugType, reportId, otherParams], where
 * - slugType is 'pr' or 're' for URLs beginning with /site/pr or /site/re
 * respectively, or the original URL if neither matches
 * - reportID is the first number contained in the URL
 * - otherParams is a string containing everything in the URL after the
 * reportID
 */
const fetchParams = (qUrl: string = '') => {
  const name = qUrl.match(/\/\d+/g)
  const val = name ? String(name.map(String)).match(/\d+/) : null
  const id = val ? val.map(Number)[0] : ''
  const reportId = String(id)
  let otherParams =
    reportId && reportId !== '' && qUrl.indexOf(reportId) > -1
      ? qUrl.split(reportId)[1]
      : qUrl
  let slugType = qUrl
  if (qUrl.startsWith('/site/re')) {
    slugType = 'sf-re'
  } else if (qUrl.startsWith('/site/pr')) {
    slugType = 'sf-pr'
  }
  otherParams = otherParams.startsWith('&')
    ? otherParams.replace('&', '?')
    : otherParams
  return [slugType, reportId, otherParams]
}

export default ({ url }: Props) => {
  // Avoid undefined for a normal url that doesn't have any extra parameter
  otherUrlParams = ''
  if (url.match(/^\/site\/pr\/[0-9]+$/g)) {
    id = Number(url.replace('/site/pr/', ''))
    slugType = 'sf-pr'
  } else if (url.match(/^\/site\/re\/[0-9]+$/g)) {
    id = Number(url.replace('/site/re/', ''))
    slugType = 'sf-re'
  } else {
    const [slug, reportId, otherParams] = fetchParams(url)
    id = Number(reportId)
    slugType = String(slug)
    otherUrlParams = otherParams
  }
  return <UrlMapping id={id} slugType={slugType} otherParams={otherUrlParams} />
}
