/**
 * Redirect Page -
 *
 * @see https://www.gatsbyjs.org/docs/add-404-page/
 */
import React, { ComponentType } from 'react'
import { Location, RouteComponentProps } from '@reach/router'

import RegexMatcher from '../components/regexMatcher'

const browser = typeof window !== 'undefined' && window
const interactiveDealTools = () => {
  const extraParams = []
  extraParams.push('t=ExecutiveSummary')
  extraParams.push('t=CashFlows')
  extraParams.push('t=Photos')
  extraParams.push('t=idt')
  return extraParams
}
const getUrlParameter = (queryString: string, name: string) => {
  const queryStringWithoutIndex = queryString.replace('/index.html', '')
  const isMatch = interactiveDealTools().some((val: any) => {
    return queryStringWithoutIndex.includes(val)
  })
  if (isMatch) {
    return decodeURIComponent(queryStringWithoutIndex)
  }

  const regex = new RegExp(`[\\?&]${name}=([^&#]*)`)
  const results = regex.exec(queryStringWithoutIndex)

  const matchedUri =
    results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '))
  return matchedUri.replace('?', '&')
}

const RedirectPage: ComponentType<RouteComponentProps> = () =>
  browser ? (
    <Location>
      {({ location }) => {
        const redirectPath = getUrlParameter(location.search, 'q')
        return <RegexMatcher url={redirectPath} />
      }}
    </Location>
  ) : null

export default RedirectPage
